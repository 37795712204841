.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10vh;
}

.main-button-container {
    margin-bottom: 40px;
}

.main-input-container {
    width: calc(60% - 30px);
    margin-bottom: 40px;
}

.main-input-label {
    font-size: 48px;
    color: white;
}

.main-input {
    cursor: text;
    min-width: 350px;
    width: 100%;
    height: 38px;
    font-size: 16px;
    color: white;
    border: 2px solid white;
    border-radius: 34px;
    box-shadow: none;
    background: transparent;
    outline: none;
    padding: 0 30px;
}

select.main-input {
    width: calc(100% + 60px);
    /*color: black;*/
}

select.main-input option {
    color: black;
}

.margin {
    margin-right: 10px;
}

.main-input:focus {
    border: 2px solid #FF5135;
    border-radius: 34px;
    outline: hidden;
}

.main-input.error {
    color: red;
    border: 2px solid red;
}

.main-button {
    min-width: 350px;
    border-radius: 34px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 16px;
    letter-spacing: -0.1px;
    font-weight: 700;
    line-height: 16px;
    text-decoration: none;
    border: none;
    cursor: pointer;
    padding: 16px 36px;
    height: 64px;
    text-align: center;
    white-space: nowrap;
    background: linear-gradient(65deg, #FF5135 0, #FF0080 100%);
    color: #fff;
    transition: background .15s ease;
}

.error {
    color: red;
}

.loading {
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 64px;
    height: 64px;
}

.loading span {
    position: absolute;
    background: #fff;
    opacity: 1;
    border-radius: 50%;
    animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loading span:nth-child(2) {
    animation-delay: -.7s;
}

.message {
    color: white;
    font-size: 16px;
}

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    background: rgba(255, 255, 255, 0.1);
}

.navbar li {
    display: inline-block;
    float: right;
    color: white;
    text-align: center;
    padding: 14px 16px;
}

.navbar li a {
    display: block;
    color: white;
    /*text-decoration: none;*/
    /*background-color: #dddddd;*/
}

.navbar li.username {
    color: red;
}

.navbar li:hover {
    background-color: #999;
}

.navbar li.active {
    float: left;
}
.navbar li.active:hover{
    background-color: transparent;
}

.navbar li.not-active {
    float: left;
}

.header-logo {
    width: 60%;
    margin-bottom: 100px;
}
.marginHorizontal {
    margin: 20px 0;
}

@keyframes loading {
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0;
    }
}

@media (max-width: 768px) {
    .header-logo{
        width: 80%;
    }
}
